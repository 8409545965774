import React from 'react'
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';


const CTA = () => {
  return (
      <section className="text-center py-5">
          <h2>Er du klar for teoriprøven?</h2>
          <MDBBtn color="success" size="lg" href="/register">Start nå og kom et steg nærmere førerkortet!</MDBBtn>
      </section>
  )
}

export default CTA