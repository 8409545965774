import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    MDBContainer,
    MDBStepper,
    MDBStepperStep,
    MDBStepperForm,
    MDBInput,
    MDBBtn,
    MDBValidationItem,
    MDBAccordion,
    MDBAccordionItem,
    MDBIcon,
    MDBAlert,
} from 'mdb-react-ui-kit';
import axios from 'axios';

const EditTest = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedTest, setSelectedTest] = useState('');
    const [tests, setTests] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [editedQuestions, setEditedQuestions] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [isNewTest, setIsNewTest] = useState(false); // Legg til en tilstand for å spore om det er en ny test
    const [newTestName, setNewTestName] = useState('');
    const [newTestDescription, setNewTestDescription] = useState('');

    // Henter tester ved oppstart av komponenten
    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await axios.get('/api/fetchTest.php');
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setTests(response.data);
                } else {
                    alert('Ingen tester tilgjengelig.');
                }
            } catch (error) {
                console.error('Feil ved innlasting av tester:', error);
                alert('En feil oppsto under innlasting av tester.');
            }
        };
        fetchTests();
    }, []);

    // Henter spørsmål for valgt test
    useEffect(() => {
        const fetchQuestions = async () => {
            if (selectedTest && !isNewTest) {
                try {
                    const response = await axios.get(`/api/fetchQuestions.php?test_id=${selectedTest}`);
                    if (response.data.success) {
                        setQuestions(response.data.questions);
                        setEditedQuestions(response.data.questions);
                    } else {
                        console.error('Ingen spørsmål tilgjengelig eller en feil oppstod');
                        alert('En feil oppsto ved innlasting av spørsmål.');
                    }
                } catch (error) {
                    console.error('Feil ved innlasting av spørsmål:', error);
                }
            } else if (isNewTest) {
                setQuestions([]);
                setEditedQuestions([]);
            }
        };
        fetchQuestions();
    }, [selectedTest, isNewTest]);

    // Håndterer valg av test eller opprettelse av ny test
    const handleTestSelection = (e) => {
        const value = e.target.value;
        if (value === 'new') {
            setIsNewTest(true);
            setSelectedTest('');
        } else {
            setIsNewTest(false);
            setSelectedTest(value);
        }
    };

    // Oppretter ny test
    const handleCreateNewTest = async () => {
        if (!newTestName || !newTestDescription) {
            setAlertMessage('Vennligst fyll inn både navn og beskrivelse for testen.');
            setAlertColor('warning');
            setShowAlert(true);
            return;
        }

        try {
            const createdAt = new Date().toISOString(); // Setter opprettelsestidspunkt
            const response = await axios.post('/api/createTest.php', {
                name: newTestName,
                description: newTestDescription,
                created_at: createdAt,
            });
            if (response.data.success) {
                const newTestId = response.data.test_id;
                setSelectedTest(newTestId); // Sett valgt test til den nye testen
                setIsNewTest(false); // Angi at vi ikke lenger lager en ny test
                setAlertMessage('Ny test ble opprettet.');
                setAlertColor('success');
                setShowAlert(true);
                setCurrentStep(2); // Gå automatisk videre til steg 2 etter opprettelse

                // Hent den oppdaterte listen over tester
                const updatedTests = await axios.get('/api/fetchTest.php');
                if (Array.isArray(updatedTests.data) && updatedTests.data.length > 0) {
                    setTests(updatedTests.data);
                }
            } else {
                setAlertMessage('Kunne ikke opprette ny test.');
                setAlertColor('danger');
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Feil ved oppretting av ny test:', error);
            setAlertMessage('En feil oppsto ved oppretting av ny test.');
            setAlertColor('danger');
            setShowAlert(true);
        }
    };




// Marker spørsmål for sletting
    const handleDeleteQuestion = (questionId) => {
        setEditedQuestions((prev) => {
            return prev.map((question) => {
                if (question.id === questionId) {
                    return {
                        ...question,
                        isDeleted: true, // Marker spørsmålet som slettet
                    };
                }
                return question;
            });
        });
    };




    // Endrer spørsmålet til redigerbart
    const handleEditQuestion = (index) => {
        setEditedQuestions((prev) => {
            const updated = [...prev];
            updated[index].isEditing = true;
            return updated;
        });
    };

    // Lagre redigerte spørsmål
    const handleSaveEdit = (index) => {
        setEditedQuestions((prev) => {
            const updated = [...prev];
            updated[index].isEditing = false;
            updated[index].isChanged = true; // Mark this question as changed
            return updated;
        });
    };

    // Håndterer endring av spørsmål eller svar
    const handleQuestionChange = (index, field, value) => {
        setEditedQuestions((prev) => {
            const updated = [...prev];
            updated[index][field] = value;
            updated[index].isChanged = true; // Mark this question as changed
            return updated;
        });
    };

    // Legg til nytt spørsmål
    const handleAddQuestion = () => {
        setEditedQuestions((prev) => [
            ...prev,
            {
                id: Date.now(),
                question_text: '',
                options: [
                    { id: `new-1`, option_text: '' },
                    { id: `new-2`, option_text: '' },
                    { id: `new-3`, option_text: '' },
                    { id: `new-4`, option_text: '' },
                ],
                correct_option: 1,
                image_url: '',
                isEditing: true,
                isNew: true,
            },
        ]);
    };


    // Håndterer bekreftelse av endringer
    const handleConfirmChanges = async () => {
        const newQuestions = editedQuestions.filter((question) => question.isNew);
        const existingQuestions = editedQuestions.filter((question) => question.isChanged && !question.isNew);
        const deletedQuestions = editedQuestions.filter((question) => question.isDeleted);

        // Formater eksisterende spørsmål
        const formattedExistingQuestions = existingQuestions.map((question) => ({
            id: question.id,
            test_id: selectedTest,
            question_text: question.question_text,
            correct_option: question.correct_option,
            image_url: question.image_url,
            options: question.options.map((option) => ({
                id: option.id,
                option_text: option.option_text,
                question_id: question.id,
            })),
        }));

        // Formater nye spørsmål
        const formattedNewQuestions = newQuestions.map((question) => ({
            test_id: selectedTest,
            question_text: question.question_text,
            correct_option: question.correct_option,
            image_url: question.image_url,
            options: question.options.map((option) => ({
                option_text: option.option_text,
            })),
        }));

        try {
            // Send eksisterende spørsmål til backend for oppdatering
            if (formattedExistingQuestions.length > 0) {
                const response = await axios.put('/api/updateQuestions.php', { questions: formattedExistingQuestions });
                if (!response.data.success) {
                    setAlertMessage('Kunne ikke lagre endringene for eksisterende spørsmål.');
                    setAlertColor('danger');
                    setShowAlert(true);
                    return;
                }
            }

            // Send nye spørsmål til backend for oppretting
            if (formattedNewQuestions.length > 0) {
                const response = await axios.post('/api/createQuestions.php', { questions: formattedNewQuestions });
                if (response.data.success) {
                    const createdQuestions = response.data.createdQuestions;

                    // Oppdater tilstanden med de nye spørsmålene og deres genererte ID-er
                    setEditedQuestions((prevQuestions) =>
                        prevQuestions.map((question) => {
                            if (question.isNew) {
                                const createdQuestion = createdQuestions.find((q) => q.tempId === question.id);
                                if (createdQuestion) {
                                    return {
                                        ...question,
                                        id: createdQuestion.id,
                                        options: question.options.map((option, index) => ({
                                            ...option,
                                            id: createdQuestion.options[index].id,
                                            question_id: createdQuestion.id,
                                        })),
                                        isNew: false, // Merk spørsmålet som ikke nytt lenger
                                    };
                                }
                            }
                            return question;
                        })
                    );

                    setAlertMessage('Nye spørsmål ble opprettet og lagret.');
                    setAlertColor('success');
                    setShowAlert(true);
                } else {
                    setAlertMessage('Kunne ikke opprette nye spørsmål.');
                    setAlertColor('danger');
                    setShowAlert(true);
                    return;
                }
            }

            // Send slettede spørsmål til backend for sletting
            if (deletedQuestions.length > 0) {
                const response = await axios.post('/api/deleteQuestions.php', { questions: deletedQuestions });
                if (!response.data.success) {
                    setAlertMessage('Kunne ikke slette spørsmål.');
                    setAlertColor('danger');
                    setShowAlert(true);
                    return;
                } else {
                    setAlertMessage('Spørsmålene ble slettet.');
                    setAlertColor('success');
                    setShowAlert(true);
                }
            }

            setAlertMessage('Endringene ble lagret.');
            setAlertColor('success');
            setShowAlert(true);
            setCurrentStep(1); // Tilbake til første steg etter vellykket lagring
        } catch (error) {
            console.error('Feil ved lagring av endringer:', error);
            setAlertMessage('En feil oppsto ved lagring av endringer.');
            setAlertColor('danger');
            setShowAlert(true);
        }
    };

    // Slett eksisterende test
    const handleDeleteTest = async () => {
        if (!selectedTest) {
            setAlertMessage('Vennligst velg en test å slette.');
            setAlertColor('warning');
            setShowAlert(true);
            return;
        }

        try {
            const response = await axios.post('/api/deleteTest.php', {
                test_id: selectedTest,
            });

            if (response.data.success) {
                setAlertMessage('Testen ble slettet.');
                setAlertColor('success');
                setShowAlert(true);
                setSelectedTest(''); // Nullstill valget etter sletting

                // Hent den oppdaterte listen over tester
                const updatedTests = await axios.get('/api/fetchTest.php');
                if (Array.isArray(updatedTests.data) && updatedTests.data.length > 0) {
                    setTests(updatedTests.data);
                } else {
                    setTests([]);
                }
            } else {
                setAlertMessage(response.data.message || 'Kunne ikke slette testen.');
                setAlertColor('danger');
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Feil ved sletting av test:', error);
            setAlertMessage('En feil oppsto ved sletting av testen.');
            setAlertColor('danger');
            setShowAlert(true);
        }
    };




    // Handle buttons for changing step
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    // MAKE RESPONSIVE STEPPER GREAT AGAIN - make it response
    const [mode, setMode] = useState("horizontal");

    const handleResize = useCallback(() => {
        const width = window.innerWidth;

        if (width > 768) {
            setMode("horizontal");
        } else if (width <= 768 && width > 400) {
            setMode("vertical");
        }
    }, []);

    useEffect(() => {
        // Perform a handleResize on mount to set the initial mode
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);


    return (
        <MDBContainer className="p-4">
            <h2>{isNewTest ? 'Opprett en ny test' : 'Endre testene dine'}</h2>
            <MDBStepper type={mode} externalNext={nextRef} externalPrev={prevRef} linear>
                <MDBStepperForm>
                    {/* Steg 1: Velg Test */}
                    <MDBStepperStep headIcon={1} headText='Velg Test' itemId={1} active={currentStep === 1}>
                        <MDBValidationItem invalid feedback='Dette feltet er påkrevd'>
                            <label htmlFor="testSelect">Velg test</label>
                            {Array.isArray(tests) && tests.length > 0 ? (
                                <select
                                    id="testSelect"
                                    value={selectedTest}
                                    onChange={handleTestSelection}
                                    required
                                    className="form-select"
                                >
                                    <option value="" disabled>Velg en test</option>
                                    <option value="new">+ Lag ny test</option>
                                    {tests.map((test) => (
                                        <option key={test.id} value={test.id}>
                                            {test.name} - {test.numQuestions} spørsmål - {test.description}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <p>Ingen tester tilgjengelig.</p>
                            )}
                        </MDBValidationItem>

                        {/* Slett-knapp */}
                        {/* {selectedTest && selectedTest !== 'new' && (
                            <MDBBtn color="danger" onClick={handleDeleteTest} className="mt-3">
                                <MDBIcon icon="trash" /> Slett valgt test
                            </MDBBtn>
                        )} */}

                        {/* Input felter for ny test hvis brukeren velger å lage en ny */}
                        {isNewTest && (
                            <div className="mt-3">
                                <MDBInput
                                    label="Navn på test"
                                    value={newTestName}
                                    onChange={(e) => setNewTestName(e.target.value)}
                                    className="mb-3"
                                    required
                                />
                                <MDBInput
                                    label="Beskrivelse av test"
                                    value={newTestDescription}
                                    onChange={(e) => setNewTestDescription(e.target.value)}
                                    className="mb-3"
                                    required
                                />
                                <MDBBtn color="info" onClick={handleCreateNewTest} className="mt-3">
                                    <MDBIcon icon="plus" /> Opprett ny test
                                </MDBBtn>
                            </div>
                        )}
                    </MDBStepperStep>

                    {/* Steg 2: Spørsmål Oversikt */}
                    <MDBStepperStep headIcon={2} headText='Oversikt Spørsmål' itemId={2}>
                        <MDBAccordion borderless initialActive={1}>
                            {editedQuestions.map((question, index) => (
                                <MDBAccordionItem
                                    collapseId={index + 1}
                                    headerTitle={`${index + 1}. ${question.question_text}`}
                                    key={question.id}
                                    bodyClassName='w-100'
                                    className={question.isDeleted ? 'bg-opacity-50 bg-danger text-danger' : ''}
                                    btnClassName={question.isDeleted ? 'bg-danger text-white' : ''}
                                >
                                    <div className="mb-3">
                                        <label><strong>Spørsmål:</strong></label>
                                        {question.isEditing ? (
                                            <MDBInput
                                                value={question.question_text}
                                                onChange={(e) => handleQuestionChange(index, 'question_text', e.target.value)}
                                                className="mb-2"
                                            />
                                        ) : (
                                            <p>{question.question_text}</p>
                                        )}
                                    </div>
                                    {question.options.map((option, optIndex) => (
                                        <div key={option.id} className="mb-2">
                                            <label><strong>Svar {optIndex + 1}:</strong></label>
                                            {question.isEditing ? (
                                                <MDBInput
                                                    value={option.option_text}
                                                    onChange={(e) => {
                                                        const updatedQuestions = [...editedQuestions];
                                                        updatedQuestions[index].options[optIndex].option_text = e.target.value;
                                                        setEditedQuestions(updatedQuestions);
                                                    }}
                                                    className="mb-2"
                                                />
                                            ) : (
                                                <p>{option.option_text}</p>
                                            )}
                                        </div>
                                    ))}
                                    <div className="mb-3">
                                        <label><strong>Bilde URL:</strong></label>
                                        {question.isEditing ? (
                                            <MDBInput
                                                value={question.image_url}
                                                onChange={(e) => handleQuestionChange(index, 'image_url', e.target.value)}
                                            />
                                        ) : (
                                            <p>{question.image_url}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label><strong>Riktig svar:</strong></label>
                                        {question.isEditing ? (
                                            <select
                                                value={question.correct_option}
                                                onChange={(e) => handleQuestionChange(index, 'correct_option', parseInt(e.target.value))}
                                                className="form-select"
                                            >
                                                {question.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={optIndex + 1}>
                                                        Svar {optIndex + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <p>Svar {question.correct_option}</p>
                                        )}
                                    </div>
                                    {question.isEditing ? (
                                        <MDBBtn color='success' outline onClick={() => handleSaveEdit(index)}>
                                            Lagre
                                        </MDBBtn>
                                    ) : (
                                            <MDBBtn color='link' className={question.isDeleted ? 'd-none' : ''} onClick={() => handleEditQuestion(index)}>
                                            Rediger
                                        </MDBBtn>
                                    )}
                                    <MDBBtn
                                        color='link'
                                        onClick={() => handleDeleteQuestion(question.id)}
                                        disabled={question.isDeleted} // Deaktiver knappen hvis spørsmålet er markert som slettet
                                    >
                                        Slett
                                    </MDBBtn>
                                </MDBAccordionItem>
                            ))}
                        </MDBAccordion>
                        <MDBBtn color="info" onClick={handleAddQuestion} className="mt-2">
                            <MDBIcon icon="plus" /> Legg til nytt spørsmål
                        </MDBBtn>
                    </MDBStepperStep>


                    {/* Steg 3: Bekreft Endringer */}
                    <MDBStepperStep headIcon={3} headText='Bekreft Endringer' itemId={3}>
                        <h4>Er du sikker på at du vil gjøre følgende endringer?</h4>
                        <ul>
                            {editedQuestions.filter((q) => q.isChanged || q.isNew || q.isDeleted).map((question, index) => (
                                <li key={question.id}>
                                    <strong>{editedQuestions.findIndex(q => q.id === question.id) + 1}. {question.question_text}:</strong>
                                    <ul>
                                        {question.isDeleted ? (
                                            <li><strong style={{ color: 'red' }}>Denne vil bli slettet</strong></li>
                                        ) : (
                                            <>
                                                <li><strong>Navn:</strong> {question.question_text}</li>
                                                {question.options.map((option, optIndex) => (
                                                    <li key={optIndex}><strong>Svaralternativ {optIndex + 1}:</strong> {option.option_text}</li>
                                                ))}
                                                <li><strong>Bilde URL:</strong> {question.image_url}</li>
                                                <li><strong>Riktig svar:</strong> Svar {question.correct_option}</li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <div className="d-flex justify-content-between my-4">
                            <MDBBtn color="success" size='lg' onClick={handleConfirmChanges}>
                                Bekreft Endringer
                            </MDBBtn>
                        </div>
                    </MDBStepperStep>
                </MDBStepperForm>
            </MDBStepper>

            {showAlert && (
                <MDBAlert open={showAlert} className='mt-3 w-100' color={alertColor} autohide dismiss onClose={() => setShowAlert(false)}>
                    {alertMessage}
                </MDBAlert>
            )}
        </MDBContainer>
    );
};

export default EditTest;
