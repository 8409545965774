import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit';

const About = () => {
  return (
      <section className="py-5">
          <MDBContainer>
              <MDBRow className="align-items-center">
                  <MDBCol md="6">
                      <h2 className='fs-1'>Litt om oss</h2>
                      <p>
                          Teoriklar er en komplett plattform for deg som forbereder deg til førerprøven. Med tilpassede
                          funksjoner, som teoretiske spørsmål kategorisert etter emne, artikler og personlig statistikk, gir vi
                          deg verktøyene du trenger for å bestå teoriprøven.
                      </p>
                      <p>
                          Vi vet at forberedelse til teoriprøven kan være krevende. Derfor har vi laget en brukervennlig, fleksibel
                          og mobilvennlig app som lar deg øve hvor som helst, når som helst.
                      </p>
                  </MDBCol>
                  <MDBCol md="6">
                      <img src="https://img.freepik.com/free-vector/driving-concept-illustration_114360-29144.jpg?t=st=1730360317~exp=1730363917~hmac=8e0e0f8320573a2d194ee2ac29a20ecb5dd7536662c75e799b8d07e785eb4097&w=1800" alt="Om Teoriklar" className="img-fluid rounded" />
                  </MDBCol>
              </MDBRow>
          </MDBContainer>
      </section>
  )
}

export default About