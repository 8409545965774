import React from 'react';
import { MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';

function GuidesSection() {
    const guides = [
        { title: 'Slik mestrer du skilt', id: 1 },
        { title: 'Førstehjelp på veien', id: 2 },
        { title: 'Hvordan forbedre teoriprøve-scoren din', id: 3 }
    ];

    return (
        <div>
            <h4 className="mb-4">Veiledninger</h4>
            <MDBListGroup>
                {guides.map((guide) => (
                    <MDBListGroupItem key={guide.id}>{guide.title}</MDBListGroupItem>
                ))}
            </MDBListGroup>
        </div>
    );
}

export default GuidesSection;
