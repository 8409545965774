import React, { useState } from 'react';
import { MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import GenerateLicense from './GenerateLicense';
import EditTest from './EditTest';
import UserAdministration from './UserAdministration';
import TestPanel from '../testPanel/TestPanel'
import ImageGallery from './ImageGallery';

const AdminPanel = () => {
    const [currentComponent, setCurrentComponent] = useState('GenerateLicense');

    const renderComponent = () => {
        switch (currentComponent) {
            case 'GenerateLicense':
                return <GenerateLicense />;
            case 'EditTest':
                return <EditTest />;
            case 'UserAdministration':
                return <UserAdministration />;
            case 'ImageGallery':
                return <ImageGallery />;
            default:
                return <TestPanel />;
        }
    };

    return (
        <MDBContainer>
            <h2>Admin Panel</h2>
            <div className="d-flex mb-4">
                <MDBBtn className='mx-2' color='secondary' outline onClick={() => setCurrentComponent('GenerateLicense')}>Produkt-lisenser</MDBBtn>
                <MDBBtn className='mx-2' color='secondary' outline onClick={() => setCurrentComponent('EditTest')}>Lag/endre en test</MDBBtn>
                <MDBBtn className='mx-2' color='secondary' outline onClick={() => setCurrentComponent('UserAdministration')}>Administrer brukere</MDBBtn>
                <MDBBtn className='mx-2' color='secondary' outline onClick={() => setCurrentComponent('ImageGallery')}>Bilde galleri</MDBBtn>
            </div>
            {renderComponent()}
        </MDBContainer>
    );
};

export default AdminPanel;
