import React, { useContext } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBIcon,
    MDBTypography,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import ResultTable from '../../components/resultTable/ResultTable';
import { UserContext } from '../../userContext';


const StudentHome = () => {
    const { user } = useContext(UserContext); // Hent brukerinformasjonen fra Context
    console.log('UserContext in StudentHome:', user); // Legg til denne linjen for å se brukerdataen

    return (
        <MDBContainer>
            <MDBContainer className="py-5">
                <MDBTypography tag='h1' className='display-5 mt-5 mb-3'>
                    Velkommen, {user.firstName ? user.firstName : 'Bruker'} {user.lastName ? user.lastName : ''}
                </MDBTypography>
                <MDBTypography tag='h3' className='mb-5 fs-3 lead'>
                    Hva ønsker du å besøke i dag?
                </MDBTypography>
                <MDBRow className="justify-content-center">
                    {/* Card for Statistikk */}
                    <MDBCol md="4" className="mb-4">
                        <MDBCard className='bg-glass p-4'>
                            <MDBCardHeader className="text-center">
                                <MDBIcon fas icon="chart-bar" size="3x" className='my-3 text-primary' />
                            </MDBCardHeader>
                            <MDBCardBody className="text-center">
                                <MDBTypography tag="h5" className="mb-3">Statistikk</MDBTypography>
                                <Link to="/statistikk" className="btn btn-primary">
                                    Gå til Statistikk
                                </Link>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    {/* Card for Veiledninger */}
                    <MDBCol md="4" className="mb-4">
                        <MDBCard className='bg-glass p-4'>
                            <MDBCardHeader className="text-center">
                                <MDBIcon fas icon="book" size="3x" className='my-3 text-primary' />
                            </MDBCardHeader>
                            <MDBCardBody className="text-center">
                                <MDBTypography tag="h5" className="mb-3">Veiledninger</MDBTypography>
                                <Link to="/veiledninger" className="btn btn-primary">
                                    Gå til Veiledninger
                                </Link>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    {/* Card for Tester */}
                    <MDBCol md="4" className="mb-4">
                        <MDBCard className='bg-glass p-4'>
                            <MDBCardHeader className="text-center">
                                <MDBIcon fas icon="file-alt" size="3x" className='my-3 text-primary' />
                            </MDBCardHeader>
                            <MDBCardBody className="text-center">
                                <MDBTypography tag="h5" className="mb-3">Tester</MDBTypography>
                                <Link to="/tester" className="btn btn-primary">
                                    Gå til Tester
                                </Link>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBContainer className='mt-5'>
                <MDBTypography tag='h2'>
                    Raskt innsyn
                </MDBTypography>
                <MDBContainer>
                    <MDBRow className="justify-content-center">
                        {/* Card for Statistikk */}
                        <MDBCol md="4" className="mb-4">
                            <ResultTable />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        </MDBContainer>
    );
};

export default StudentHome;
