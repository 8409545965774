// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landingPage/LandingPage';
import Dashboard from './dashboard/Dashboard';
import Login from './container/login/Login';
import Register from './container/register/Register';
import Navbar from './container/navbar/Navbar'
import TestSection from './dashboard/testsSection/TestsSection';
import StudentHome from './dashboard/adminPanel/StudentHome';
import { UserProvider } from './userContext';

function App() {
  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/home" element={<StudentHome />} />
        <Route path="/test" element={<TestSection />} />
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </Router>
    </UserProvider>
  );
}

export default App;
