import React from 'react';
import Hero from '../container/hero/Hero'
import Features from '../container/features/Features'
import Navbar from '../container/navbar/Navbar';
import CTA from '../container/CTA/CTA';
import Footer from '../container/footer/Footer'
import About from '../container/about/About';
import Customers from '../container/customers/Customers';

function LandingPage() {
    return (
        <>
        <Navbar />
        <Hero />
        <About />
        <CTA />
        <Features />
        <Customers />
        <Footer />
        </>
    );
}

export default LandingPage;
