import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBContainer, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBRadio } from 'mdb-react-ui-kit';

const Test = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/questions.php'); // Husk å justere URL
                setQuestions(response.data);
                setError(null);
            } catch (err) {
                setError('Kunne ikke hente spørsmål. Vennligst prøv igjen senere.');
                console.error('Error fetching questions:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchQuestions();
    }, []);

    const handleOptionChange = (event) => setSelectedOption(parseInt(event.target.value));

    const handleSubmit = () => {
        const currentQuestion = questions[currentQuestionIndex];
        if (selectedOption === currentQuestion.correct_option) {
            setFeedback('Riktig svar!');
        } else {
            setFeedback('Galt svar. Prøv igjen!');
        }
    };

    const handleNextQuestion = () => {
        setFeedback('');
        setSelectedOption(null);
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    };

    if (loading) return <p>Laster spørsmål...</p>;
    if (error) return <p>{error}</p>;
    if (questions.length === 0) return <p>Ingen spørsmål tilgjengelig.</p>;

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <MDBContainer className="p-3">
            <MDBCard className="mb-3">
                <MDBCardBody>
                    <MDBCardTitle>{currentQuestion.question_text}</MDBCardTitle>
                    <div>
                        {[1, 2, 3, 4].map((optionNumber) => (
                            <MDBRadio
                                key={optionNumber}
                                name="options"
                                value={optionNumber}
                                label={currentQuestion[`option_${optionNumber}`]}
                                checked={selectedOption === optionNumber}
                                onChange={handleOptionChange}
                            />
                        ))}
                    </div>
                    <MDBBtn color="primary" onClick={handleSubmit} disabled={selectedOption === null}>
                        Sjekk svar
                    </MDBBtn>
                    <p>{feedback}</p>
                    {feedback && currentQuestionIndex < questions.length - 1 && (
                        <MDBBtn color="secondary" onClick={handleNextQuestion}>
                            Neste spørsmål
                        </MDBBtn>
                    )}
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Test;
