import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit';

const Customers = () => {
  return (
      <section className="py-5" style={{ backgroundColor: '#f8f9fa' }}>
          <MDBContainer>
              <h3 className="text-center mb-5 fs-5">Hva sier brukerne våre?</h3>
              <MDBRow className="gy-4 text-center">

                  <MDBCol md="4">
                      <MDBCard>
                          <MDBCardBody>
                              <MDBCardImage src="https://via.placeholder.com/80" alt="Kunde" className="rounded-circle mb-3" />
                              <MDBCardTitle>Maria A.</MDBCardTitle>
                              <MDBCardText>"Teoriklar gjorde det lett å øve på farten. Jeg klarte prøven på første forsøk!"</MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

                  <MDBCol md="4">
                      <MDBCard>
                          <MDBCardBody>
                              <MDBCardImage src="https://via.placeholder.com/80" alt="Kunde" className="rounded-circle mb-3" />
                              <MDBCardTitle>Jens H.</MDBCardTitle>
                              <MDBCardText>"Enkel og intuitiv app! Jeg elsket hvordan jeg kunne følge fremgangen min."</MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

                  <MDBCol md="4">
                      <MDBCard>
                          <MDBCardBody>
                              <MDBCardImage src="https://via.placeholder.com/80" alt="Kunde" className="rounded-circle mb-3" />
                              <MDBCardTitle>Nora K.</MDBCardTitle>
                              <MDBCardText>"Veldig nyttige artikler og spørsmål! Hjalp meg virkelig å forstå alle temaene."</MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

              </MDBRow>
          </MDBContainer>
      </section>
  )
}

export default Customers