import { MDBContainer } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

function GraphSection() {
    const [progressData, setProgressData] = useState([]);
    const [numTestsTaken, setNumTestsTaken] = useState(0);

    useEffect(() => {
        const fetchProgressData = async () => {
            try {
                const response = await axios.get('/api/fetchUserTestResults.php');
                if (response.data.success) {
                    const results = response.data.results;
                    setNumTestsTaken(results.length);

                    if (results.length >= 3) {
                        const formattedData = results.map((result, index) => ({
                            name: `Forsøk: ${index + 1}`,
                            score: result.score,
                            tidsbruk: result.time_spent,
                            dato: result.date
                        }));
                        setProgressData(formattedData);
                    }
                } else {
                    console.error('Feil ved henting av fremdriftsdata:', response.data.message);
                }
            } catch (error) {
                console.error('Feil ved henting av fremdriftsdata:', error);
            }
        };

        fetchProgressData();
    }, []);

    return (
        <MDBContainer className='my-4'>
            <h4 className="mb-4 display-5">Din fremdrift</h4>
            <div className="bg-secondary bg-opacity-10 shadow-5-strong rounded p-3">
                {numTestsTaken >= 3 ? (
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={progressData}>
                            <Line type="monotone" dataKey="score" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <p className="text-center my-4">
                        Du må ta prøven flere ganger. {numTestsTaken}/3 fullført
                    </p>
                )}
            </div>
        </MDBContainer>
    );
}

export default GraphSection;
