import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBAlert,
    MDBTypography,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBIcon,
} from 'mdb-react-ui-kit';

const GenerateLicense = () => {
    const [tests, setTests] = useState([]);
    const [selectedTestId, setSelectedTestId] = useState('');
    const [generatedCode, setGeneratedCode] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('');
    const [licenses, setLicenses] = useState([]);

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await axios.get('/api/fetchTest.php');
                if (response.data && Array.isArray(response.data)) {
                    setTests(response.data);
                } else {
                    setAlertMessage('Ingen tester funnet.');
                    setAlertColor('warning');
                }
            } catch (error) {
                setAlertMessage('Feil ved henting av tester. Prøv igjen senere.');
                setAlertColor('danger');
            }
        };

        const fetchLicenses = async () => {
            try {
                const response = await axios.get('/api/fetchLicenses.php');
                if (response.data && response.data.success && Array.isArray(response.data.data)) {
                    setLicenses(response.data.data);
                } else {
                    setAlertMessage('Ingen lisenskoder funnet.');
                    setAlertColor('warning');
                }
            } catch (error) {
                setAlertMessage('Feil ved henting av lisenskoder. Prøv igjen senere.');
                setAlertColor('danger');
            }
        };

        fetchTests();
        fetchLicenses();
    }, []);

    const handleGenerateLicense = async () => {
        if (!selectedTestId) {
            setAlertMessage('Vennligst velg en test for å generere en lisenskode.');
            setAlertColor('warning');
            return;
        }

        try {
            const response = await axios.post('/api/generateLicense.php', { test_id: selectedTestId });
            if (response.data.success) {
                setGeneratedCode(response.data.code); // Vise den genererte koden
                setAlertMessage('Lisenskode generert!');
                setAlertColor('success');

                // Oppdater lisenslisten med den nye lisensen fra responsen
                if (response.data.license) {
                    setLicenses((prevLicenses) => [...prevLicenses, response.data.license]);
                }
            } else {
                setAlertMessage(response.data.message || 'Kunne ikke generere lisenskode.');
                setAlertColor('danger');
            }
        } catch (error) {
            console.error('Feil ved generering av lisenskode:', error);
            setAlertMessage('En feil oppsto ved generering av lisenskode. Prøv igjen senere.');
            setAlertColor('danger');
        }
    };


    const handleDeleteLicense = async (licenseId) => {
        try {
            const response = await axios.post('/api/deleteLicense.php', { id: licenseId });
            if (response.data.success) {
                setLicenses((prevLicenses) => prevLicenses.filter((license) => license.id !== licenseId));
                setAlertMessage('Lisenskode slettet.');
                setAlertColor('success');
            } else {
                setAlertMessage(response.data.message || 'Kunne ikke slette lisenskode.');
                setAlertColor('danger');
            }
        } catch (error) {
            setAlertMessage('En feil oppsto ved sletting av lisenskode. Prøv igjen senere.');
            setAlertColor('danger');
        }
    };

    const handleCopyLicense = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            setAlertMessage('Lisenskode kopiert til utklippstavlen.');
            setAlertColor('info');
        }).catch(() => {
            setAlertMessage('Kunne ikke kopiere lisenskode. Prøv igjen.');
            setAlertColor('danger');
        });
    };

    return (
        <MDBContainer className="py-5">
            <MDBRow className="justify-content-center">
                <MDBCol md="6">
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle className="text-center mb-4">Generer Lisenskode</MDBCardTitle>
                            {alertMessage && (
                                <MDBAlert color={alertColor} dismiss onClose={() => setAlertMessage(null)}>
                                    {alertMessage}
                                </MDBAlert>
                            )}
                            <MDBTypography tag='h5' className='mb-3'>Velg en test</MDBTypography>
                            <select
                                className="form-select mb-4"
                                value={selectedTestId}
                                onChange={(e) => setSelectedTestId(e.target.value)}
                            >
                                <option value="" disabled>Velg en test</option>
                                {tests.map((test) => (
                                    <option key={test.id} value={test.id}>{test.name}</option>
                                ))}
                            </select>
                            <MDBBtn color="primary" block onClick={handleGenerateLicense}>Generer Lisenskode</MDBBtn>
                            {generatedCode && (
                                <MDBTypography tag='h5' className='mt-4 text-center'>
                                    Generert Lisenskode: <strong>{generatedCode}</strong>
                                </MDBTypography>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            <MDBRow className="justify-content-center mt-5">
                <MDBCol md="10">
                    <MDBTypography tag='h4' className='mb-4'>Alle Lisenskoder</MDBTypography>
                    <MDBTable responsive>
                        <MDBTableHead light>
                            <tr>
                                <th>#</th>
                                <th>Lisenskode</th>
                                <th>Testnavn</th>
                                <th>Status</th>
                                <th>Handlinger</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {licenses.map((license, index) => (
                                <tr key={license.id}>
                                    <td>{index + 1}</td>
                                    <td>{license.code}</td>
                                    <td>{license.test_name}</td>
                                    <td>
                                        {license.is_active
                                            ? 'Ikke brukt'
                                            : `Brukt av: ${license.first_name} ${license.last_name}`}
                                    </td>
                                    <td>
                                        <MDBBtn color="danger" size="sm" onClick={() => handleDeleteLicense(license.id)} className="me-2">
                                            <MDBIcon icon="trash" /> Slett
                                        </MDBBtn>
                                        <MDBBtn color="secondary" size="sm" onClick={() => handleCopyLicense(license.code)}>
                                            <MDBIcon icon="copy" /> Kopier
                                        </MDBBtn>
                                    </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default GenerateLicense;
