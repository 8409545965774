import React from 'react'
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';


const Footer = () => {
  return (
    <footer className="text-center w-100 static-bottom" style={{ backgroundColor: '#f5f5f5' }}>
      <div className="py-2">
          <p>© 2024 Teoriklar. Alle rettigheter reservert.</p>
          <MDBIcon fab icon="facebook" className="me-3" style={{cursor: 'pointer'}} />
          <MDBIcon fab icon="twitter" className="me-3" style={{cursor: 'pointer'}} />
          <MDBIcon fab icon="instagram" style={{cursor: 'pointer'}} />
      </div>
      <div className="w-100 p-1 text-center" style={{ backgroundColor: '#f5f5f5' }}>
          <p className="text-center text-secondary mt-4">Denne nettsiden er laget av <a href="https://Dencker.no">Dencker.no</a></p>
      </div>
      </footer>
  )
}

export default Footer