import React from 'react'
import { MDBBtn } from 'mdb-react-ui-kit'

const Hero = () => {
  return (
      <section className="text-center p-5 bg-image"
          style={{ 
            backgroundImage: "url('https://img.freepik.com/free-photo/adventurous-spirit-driving-electric-car-scenic-route-amid-freshness-summer_73899-23801.jpg?t=st=1730293450~exp=1730297050~hmac=930ac54bed54dda6d83c56be6e18551fca3b643f7442c6bee08a30ace2d6b24b&w=2000')",
            height: '600px'
        }}
      >
          <div className='mask text-light p-5' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
          <h1 className='display-3 text-uppercase'>Forbered deg til <br />
                  førerprøven med <span className='text-danger'>Teori</span><span className='text-info fw-bold'>Klar</span></h1>
          <p className="lead">Alt du trenger for å bestå teoriprøven - på ett sted</p>
          <MDBBtn color="primary" size="lg" href="/register">Kom i gang</MDBBtn>
          </div>
      </section>
  )
}

export default Hero