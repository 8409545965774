import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBAlert, MDBIcon } from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const userData = {
            firstName,
            lastName,
            username,
            email,
            username,
            password,
            phone,
        };

        try {
            const response = await fetch('/api/register.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                setError('');

                // Automatically log in the user after successful registration
                const loginData = { email, password };
                const loginResponse = await fetch('/api/login.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginData),
                });

                const loginDataResponse = await loginResponse.json();
                if (loginResponse.ok) {
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('role', data.role); // Ensure 'role' is stored
                    
                    // Redirect to the dashboard upon successful login
                    navigate('/dashboard');
                } else {
                    setError(loginDataResponse.message);
                    setMessage('');
                }
            } else {
                setError(data.message); // Show error message
                setMessage(''); // Clear success message
            }
        } catch (error) {
            setError("An error occurred.");
            setMessage('');
        }
    };

    return (
        <MDBContainer className="my-5">
            <Link to='/'>
                <MDBBtn color='link' className='fs-6 text-center d-flex flex-row justify-content-center align-items-center ms-2'>
                    <MDBIcon fas icon="angle-double-left" size='1x' className='mx-2 my-auto' />
                    Tilbake til hjem
                </MDBBtn>
            </Link>
            <MDBRow className="justify-content-center">
                <MDBCol md="6">
                    <MDBCard>
                        <MDBCardBody>
                            <h3 className="text-center mb-4">Registrer deg</h3>
                            <form onSubmit={handleRegister}>
                                <MDBInput label="Fornavn" placeholder='Ola' type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required className="mb-4" />
                                <MDBInput label="Etternavn" placeholder='Nordmann' type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required className="mb-4" />
                                <MDBInput label="E-post" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="mb-4" />
                                <MDBInput label="Brukernavn" type="text" value={email} onChange={(e) => setUsername(e.target.value)} required wrapperClass="d-none" />
                                <MDBInput label="Passord" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="mb-4" />
                                <MDBInput label="Telefonnummer" type="text" placeholder='12345678' value={phone} onChange={(e) => setPhone(e.target.value)} required className="mb-4" />
                                <MDBBtn type="submit" block>Registrer</MDBBtn>
                            </form>
                            {message && <MDBAlert color="info" className="mt-3">{message}</MDBAlert>}
                            <p className="mt-3 text-center">
                                Allerede registrert? <Link to="/login">Logg inn her</Link>
                            </p>
                            {error && <MDBAlert color="danger" className="mt-3">{error}</MDBAlert>}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Register;
