import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    MDBContainer,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardImage,
    MDBRadio,
    MDBRow,
    MDBCol,
    MDBCardText,
    MDBPagination,
    MDBPaginationItem,
    MDBPaginationLink
} from 'mdb-react-ui-kit';

const Test = ({ testId, onBack }) => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [submittedAnswers, setSubmittedAnswers] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [timeSpent, setTimeSpent] = useState(0);

    // Function to shuffle an array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    };

    useEffect(() => {
        // Fetch questions from the backend using the provided testId
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`/api/questions.php?test_id=${testId}`);
                if (Array.isArray(response.data)) {
                    const shuffledQuestions = [...response.data];
                    shuffleArray(shuffledQuestions); // Shuffle the questions
                    setQuestions(shuffledQuestions);
                    setStartTime(new Date()); // Record start time when questions load
                } else {
                    console.error('Unexpected response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [testId]);

    const handleOptionChange = (event) => {
        setSelectedOption(parseInt(event.target.value));
    };

    const handleNextQuestion = () => {
        saveAnswer();
        if (currentQuestionIndex === questions.length - 1) {
            finishTest();
        } else {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setSelectedOption(null);
        }
    };

    const handleJumpToQuestion = (index) => {
        saveAnswer();
        setCurrentQuestionIndex(index);
        setSelectedOption(null);
    };

    const finishTest = async () => {
        saveAnswer();
        const timeSpentInSeconds = Math.floor((new Date() - startTime) / 1000);
        setTimeSpent(timeSpentInSeconds);
        setShowResults(true);

        // Save test results to the database
        try {
            const response = await axios.post('/api/saveTestResults.php', {
                test_id: testId,
                time_spent: timeSpentInSeconds,
                answers: submittedAnswers
            });

            if (!response.data.success) {
                console.error('Error saving test results:', response.data.message);
            }
        } catch (error) {
            console.error('Error saving test results:', error);
        }
    };

    const saveAnswer = () => {
        const currentQuestion = questions[currentQuestionIndex];
        const correctOptionId = currentQuestion.options[currentQuestion.correct_option - 1].option_id;

        const isCorrect = selectedOption === correctOptionId;
        setSubmittedAnswers((prevAnswers) => [
            ...prevAnswers,
            {
                questionId: currentQuestion.question_id,
                questionText: currentQuestion.question_text,
                selectedOption,
                correctOptionId,
                isCorrect,
                options: currentQuestion.options
            }
        ]);
    };

    if (questions.length === 0) return <p>Laster spørsmål...</p>;

    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion || !currentQuestion.options) return <p>Feil ved lasting av spørsmålet.</p>;

    const incorrectAnswers = submittedAnswers.filter((answer) => !answer.isCorrect).length;
    const hasPassed = incorrectAnswers <= 7;

    return (
        <MDBContainer className="p-3">
            <MDBBtn color="secondary" onClick={onBack} className="mb-3">
                Tilbake
            </MDBBtn>
            {!showResults && (
                <>
                    <MDBCard className="mb-3">
                        <MDBCardBody>
                            {currentQuestion.image_url && (
                                <div style={{ maxWidth: '500px', maxHeight: '500px', }} className="w-100 d-flex justify-content-center align-items-center mx-auto">
                                    <MDBCardImage
                                        src={currentQuestion.image_url}
                                        alt="Question Image"
                                        className="mt-2 mb-4 rounded w-100 h-100"
                                        fluid
                                        style={{minHeight: '500px'}}
                                    />
                                </div>
                            )}
                            <MDBCardTitle className='my-2'>{currentQuestion.question_text}</MDBCardTitle>
                            <div>
                                {currentQuestion.options.map((option) => (
                                    <MDBRadio
                                        key={option.option_id}
                                        name="options"
                                        value={option.option_id}
                                        label={option.option_text}
                                        checked={selectedOption === option.option_id}
                                        onChange={handleOptionChange}
                                        wrapperClass='mb-2 p-3 bg-secondary bg-opacity-10 rounded'
                                        className='position-relative'
                                        labelClass='position-relative'
                                        labelStyle={{ left: '20px' }}
                                        style={{ left: '20px' }}
                                    />
                                ))}
                            </div>
                            <MDBBtn
                                color="primary"
                                onClick={handleNextQuestion}
                                disabled={selectedOption === null}
                                className="mt-3"
                            >
                                {currentQuestionIndex === questions.length - 1 ? 'Fullfør' : 'Fortsett'}
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBPagination className="justify-content-center mt-4 d-flex flex-wrap">
                        {questions.map((_, index) => (
                            <MDBPaginationItem key={index} active={index === currentQuestionIndex}>
                                <MDBPaginationLink onClick={() => handleJumpToQuestion(index)}>
                                    {index + 1}
                                </MDBPaginationLink>
                            </MDBPaginationItem>
                        ))}
                    </MDBPagination>
                </>
            )}

            {showResults && (
                <div>
                    <h2 className={`text-center my-4 ${hasPassed ? 'text-success' : 'text-danger'}`}>
                        {hasPassed ? 'Gratulerer, du har bestått!' : 'Beklager, du må nok øve litt mer.'}
                    </h2>
                    <h4>Resultater:</h4>
                    <p>Total tid brukt: {timeSpent} sekunder</p>
                    {submittedAnswers.map((answer, index) => {
                        const userAnswer = answer.options.find(opt => opt.option_id === answer.selectedOption);
                        const correctAnswer = answer.options.find(opt => opt.option_id === answer.correctOptionId);

                        return (
                            <MDBCard
                                key={index}
                                className={`mb-3 ${answer.isCorrect ? 'bg-success text-dark' : 'bg-danger text-dark'}`}
                            >
                                <MDBCardBody>
                                    <MDBCardTitle>Spørsmål {index + 1}: {answer.questionText}</MDBCardTitle>
                                    <MDBCardText>
                                        <strong>Ditt svar:</strong> {userAnswer?.option_text || 'Ikke svart'}
                                    </MDBCardText>
                                    {!answer.isCorrect && (
                                        <MDBCardText>
                                            <strong>Riktig svar er:</strong> {correctAnswer?.option_text || 'N/A'}
                                        </MDBCardText>
                                    )}
                                </MDBCardBody>
                            </MDBCard>
                        );
                    })}
                </div>
            )}
        </MDBContainer>
    );
};

export default Test;
