import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    MDBContainer,
    MDBBtn,
    MDBLightbox,
    MDBLightboxItem,
    MDBInput,
    MDBFile,
    MDBRow,
    MDBCol,
    MDBAlert
} from 'mdb-react-ui-kit';

const ImageGallery = () => {
    const [images, setImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('');

    // Hent alle bilder når komponenten mountes
    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await axios.get('/api/image/getImages.php');
            if (response.data.success) {
                setImages(response.data.images);
            } else {
                console.error('Failed to fetch images:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    // Håndterer opplasting av fil
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadMessage('Vennligst velg en fil før opplasting.');
            setAlertColor('warning');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await axios.post('/api/image/uploadImage.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setUploadMessage('Bildet ble lastet opp!');
                setAlertColor('success');
                setSelectedFile(null);
                fetchImages(); // Oppdater listen over bilder etter opplasting
            } else {
                setUploadMessage('Kunne ikke laste opp bildet.');
                setAlertColor('danger');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            setUploadMessage('En feil oppsto ved opplasting.');
            setAlertColor('danger');
        }
    };

    return (
        <MDBContainer className="py-5">
            <h2 className="text-center mb-4">Bildegalleri</h2>

            {uploadMessage && (
                <MDBAlert color={alertColor} dismiss onClose={() => setUploadMessage(null)}>
                    {uploadMessage}
                </MDBAlert>
            )}

            {/* Filopplastning */}
            <MDBRow className="mb-4">
                <MDBCol md="6">
                    <MDBFile label="Velg bilde for opplasting" onChange={handleFileChange} />
                </MDBCol>
                <MDBCol md="6" className="text-md-end">
                    <MDBBtn color="primary" onClick={handleUpload}>
                        Last opp bilde
                    </MDBBtn>
                </MDBCol>
            </MDBRow>

            {/* Lightbox Galleri */}
            {images.length > 0 ? (
                <MDBLightbox>
                    <MDBRow>
                        {images.map((image) => (
                            <MDBCol md="4" lg="3" key={image.id} className="mb-4">
                                <MDBLightboxItem
                                    src={image.image_url ? image.image_url : 'https://via.placeholder.com/500x500'}
                                    alt={image.filename || 'Bilde'}
                                >
                                    <img
                                        src={image.image_url ? image.image_url : 'https://via.placeholder.com/500x500'}
                                        alt={image.filename || 'Bilde'}
                                        className="img-fluid z-depth-1"
                                    />
                                </MDBLightboxItem>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBLightbox>
            ) : (
                <p>Ingen bilder funnet.</p>
            )}
        </MDBContainer>
    );
};

export default ImageGallery;
