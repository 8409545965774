import React, { useState } from 'react';
import './Navbar.css'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
} from 'mdb-react-ui-kit';

import Logo from '../../assets/logo-banner.png'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [openBasic, setOpenBasic] = useState(false);

  return (
    <MDBNavbar expand='lg' className='p-0 nav-wrapper m-0 w-100 text-light'>
      <MDBContainer fluid className='nav-bg' style={{ maxWidth: '992px' }}>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon='bars' fas color='dark' />
        </MDBNavbarToggler>

        <div className="w-100" style={{ display: 'contents' }}>
          <MDBNavbarBrand href='#'>
            <img src={Logo} alt="" height="64px" />
          </MDBNavbarBrand>

          <MDBCollapse navbar open={openBasic}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center text-dark'>
              <div className="w-100 justify-content-start d-flex nav-links-wrapper align-items-center">
              <MDBNavbarItem className='mx-2'>
                  <Link to='/'>
                <MDBNavbarLink active aria-current='page' href='#'>
                    Hjem
                </MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem className='mx-2'>
                  <Link to='/'>
                <MDBNavbarLink active aria-current='page' href='#'>
                    Om oss
                </MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem className='mx-2'>
                  <Link to='/'>
                <MDBNavbarLink active aria-current='page' href='#'>
                    Hva vi kan tilby
                </MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              </div>
              <div className="w-75 d-flex flex-row nav-btn-wrapper">
                  <Link to='/login'>
                  <MDBBtn color='primary' outline className='mx-2' size='sm'>
                <MDBNavbarLink active aria-current='page' href='#'>
                      Login
                </MDBNavbarLink>
                </MDBBtn>
                  </Link>
                  <Link to='/register'>
              <MDBBtn color='secondary' className='mx-2' size='sm'>
                <MDBNavbarLink active aria-current='page' href='#'>
                    Registrer deg her
                </MDBNavbarLink>
                  </MDBBtn>
                  </Link>
              </div>
            </MDBNavbarNav>
          </MDBCollapse>
        </div>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default Navbar