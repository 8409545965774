import React, { useEffect, useState } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import axios from 'axios';
import moment from 'moment';

function TableSection() {
    const [groupedResults, setGroupedResults] = useState({});

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get('/api/fetchUserTestResults.php');
                if (response.data.success) {
                    const results = response.data.results;
                    groupResults(results);
                } else {
                    console.error('Feil ved henting av resultater:', response.data.message);
                }
            } catch (error) {
                console.error('Feil ved henting av resultater:', error);
            }
        };

        const groupResults = (results) => {
            const grouped = {
                today: [],
                lastWeek: [],
                thisMonth: [],
                previousMonths: {}
            };

            results.forEach((result) => {
                const resultDate = moment(result.date, 'DD.MM.YYYY, [kl.] HH:mm', true);
                const today = moment().startOf('day');
                const startOfWeek = moment().subtract(1, 'weeks').startOf('isoWeek');
                const startOfMonth = moment().startOf('month');

                if (resultDate.isSame(today, 'day')) {
                    grouped.today.push(result);
                } else if (resultDate.isAfter(startOfWeek) && resultDate.isBefore(today)) {
                    grouped.lastWeek.push(result);
                } else if (resultDate.isAfter(startOfMonth) && resultDate.isBefore(today)) {
                    grouped.thisMonth.push(result);
                } else {
                    const monthKey = resultDate.format('MMMM YYYY');
                    if (!grouped.previousMonths[monthKey]) {
                        grouped.previousMonths[monthKey] = [];
                    }
                    grouped.previousMonths[monthKey].push(result);
                }
            });

            setGroupedResults(grouped);
        };


        fetchResults();
    }, []);

    const ResultTable = ({ title, results }) => (
        <>
            <MDBTypography className='fs-6 lead mt-5'>{title}</MDBTypography>
            <MDBTable hover responsive>
                <MDBTableHead>
                    <tr>
                        <th>#</th>
                        <th>Dato</th>
                        <th>Score</th>
                        <th>Resultat</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {results.map((result, index) => (
                        <tr key={result.id}>
                            <td>{index + 1}</td>
                            <td>{result.date}</td>
                            <td>{result.score}%</td>
                            <td>{result.score >= 80 ? 'Bestått' : 'Ikke bestått'}</td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </>
    );

    
    return (
        <MDBContainer className='my-5'>
            <h4 className="mb-4 display-5">Dine Prøveresultater</h4>
            {Object.keys(groupedResults).length > 0 && (
                <>
                    {groupedResults.today.length > 0 && (
                        <ResultTable title="I dag" results={groupedResults.today} />
                    )}

                    {groupedResults.lastWeek.length > 0 && (
                        <ResultTable title="Forrige uke" results={groupedResults.lastWeek} />
                    )}

                    {groupedResults.thisMonth.length > 0 && (
                        <ResultTable title="Denne måneden" results={groupedResults.thisMonth} />
                    )}

                    {Object.keys(groupedResults.previousMonths).length > 0 &&
                        Object.keys(groupedResults.previousMonths).map((monthKey) => (
                            <ResultTable key={monthKey} title={monthKey} results={groupedResults.previousMonths[monthKey]} />
                        ))
                    }

                </>
            )}
        </MDBContainer>
    );
}

export default TableSection;
