import React from 'react'
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBBtn, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Features = () => {
  return (
      <section className="py-5" style={{ backgroundColor: '#f8f9fa' }}>
          <MDBContainer>
              <h2 className="text-center mb-5">Viktige Funksjoner</h2>
              <MDBRow className="gy-5">

                  <MDBCol md="6" lg="4">
                      <MDBCard className="h-100">
                          <MDBCardImage src="https://img.freepik.com/free-photo/3d-checklist-clipboard-render-illustration_107791-16457.jpg?t=st=1730294768~exp=1730298368~hmac=932e2dbb4eb21a4d26454edecb357bdb9e0d15c72b84fdc3d36c7e3a1eae5b0e&w=1800" alt="Teoriprøver" position="top" height='300px' />
                          <MDBCardBody>
                              <MDBIcon icon="clipboard-check" size="2x" className="text-primary mb-3" />
                              <MDBCardTitle>Komplette teoriprøver</MDBCardTitle>
                              <MDBCardText>
                                  Tilgang til alle nødvendige spørsmål for teoriprøven, inkludert spørsmål organisert etter temaer som skilt, førstehjelp, og sikkerhet. Øv på hele prøver eller kapitler etter eget behov.
                              </MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

                  <MDBCol md="6" lg="4">
                      <MDBCard className="h-100">
                          <MDBCardImage src="https://img.freepik.com/free-vector/graphic-background_53876-90532.jpg?t=st=1730294249~exp=1730297849~hmac=1353cbf72464d2d5771c77c4214eb4c8830586f342526bcb42bb9167f19d1eef&w=1800" alt="Statistikk" position="top" height='300px' />
                          <MDBCardBody>
                              <MDBIcon icon="chart-pie" size="2x" className="text-success mb-3" />
                              <MDBCardTitle>Detaljert Statistikk</MDBCardTitle>
                              <MDBCardText>
                                  Følg din utvikling over tid. Få innsikt i hvilke kategorier du mestrer og hvor du kan forbedre deg, slik at du kan bruke tiden din mest mulig effektivt.
                              </MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

                  <MDBCol md="6" lg="4">
                      <MDBCard className="h-100">
                          <MDBCardImage src="https://img.freepik.com/free-photo/aerial-view-man-typing-retro-typewriter_53876-40994.jpg?uid=R24715230&ga=GA1.1.911999570.1723634963&semt=ais_hybrid" alt="Artikler" position="top" height='300px' />
                          <MDBCardBody>
                              <MDBIcon icon="newspaper" size="2x" className="text-info mb-3" />
                              <MDBCardTitle>Oppdaterte Artikler</MDBCardTitle>
                              <MDBCardText>
                                  Hold deg oppdatert på siste nytt med veiledende artikler som hjelper deg med å mestre teoriprøven og gir deg innsikt i relevante temaer.
                              </MDBCardText>
                          </MDBCardBody>
                      </MDBCard>
                  </MDBCol>

              </MDBRow>
          </MDBContainer>
      </section>
  )
}

export default Features