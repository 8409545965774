import React, { useState, useContext, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBAlert, MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../../userContext';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setUser } = useContext(UserContext); // Bruker context for å sette brukerdata
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            checkSessionStatus();
        }, 300000); // 300 000 ms = 5 minutter

        return () => clearInterval(interval); // Rydder opp når komponenten demonteres
    }, []);

    // Funksjon for å sjekke sessionstatus
    const checkSessionStatus = async () => {
        try {
            const response = await fetch('/api/checkSession.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (!data.success) {
                // Hvis session er utløpt, naviger til login og vis melding
                localStorage.removeItem('isLoggedIn');
                setError('Sessionen har utløpt. Logg inn på nytt.');
                navigate('/login');
            }
        } catch (error) {
            console.error('Feil ved session sjekk:', error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const userData = { email, password };

        try {
            const response = await fetch('/api/login.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            const data = await response.json();

            if (data.success) {
                console.log("Data fra backend:", data);
                // Sett brukeren i Context
                setUser({
                    userId: data.user_id,       // Sørg for at backend returnerer user_id
                    role: data.role,            // Sørg for at backend returnerer role
                    firstName: data.first_name, // Sørg for at backend returnerer first_name
                    lastName: data.last_name,   // Sørg for at backend returnerer last_name
                });

                // Sett loggedIn token i localStorage
                localStorage.setItem('isLoggedIn', 'true');

                // Naviger til dashboardet
                navigate('/dashboard');
            } else {
                setError(data.message);
            }
        } catch (error) {
            console.error('En feil oppsto ved innlogging:', error);
            setError('En feil oppsto ved innlogging.');
        }
    };




    return (
        <MDBContainer fluid className="my-5">
            <Link to='/'>
            <MDBBtn color='link' className='fs-6 text-center d-flex flex-row justify-content-center align-items-center ms-2'>
                <MDBIcon fas icon="angle-double-left" size='1x' className='mx-2 my-auto' />
                Tilbake til hjem
                </MDBBtn>
            </Link>
        <MDBContainer className="my-5">
            <MDBRow className="justify-content-center">
                <MDBCol md="6">
                    <MDBCard>
                        <MDBCardBody>
                            <h3 className="text-center mb-4">Logg inn</h3>
                            <form onSubmit={handleLogin}>
                                <MDBInput label="E-post" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="mb-4" />
                                <MDBInput label="Passord" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="mb-4" />
                                <MDBBtn type="submit" block>Logg inn</MDBBtn>
                            </form>
                            {message && (
                                <MDBAlert open={showAlert} color="info" className="mt-3" autohide show={!!message}>
                                    {message}
                                </MDBAlert>
                            )}
                            <p className="mt-3 text-center">
                                Ikke registrert? <Link to="/register">Registrer deg her</Link>
                            </p>
                            {error && (
                                <MDBAlert open={showAlert} color="danger" className="mt-3" show={!!error}>
                                    {error}
                                </MDBAlert>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </MDBContainer>
    );
}

export default Login;
