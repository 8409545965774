import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    MDBContainer, MDBTable, MDBTableHead, MDBTableBody,
    MDBBtn, MDBInput, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader,
    MDBModalBody, MDBModalFooter, MDBIcon, MDBAlert
} from 'mdb-react-ui-kit';

const UserAdministration = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [assignLicenseModalOpen, setAssignLicenseModalOpen] = useState(false);
    const [selectedTestId, setSelectedTestId] = useState('');
    const [tests, setTests] = useState([]); // For alle tilgjengelige tester
    const [licenseCode, setLicenseCode] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    // Hent alle brukere
    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/brukeradministrasjon/getUsers.php');
            if (response.data.success) {
                setUsers(response.data.users);
            } else {
                console.error('Failed to fetch users:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Hent alle tester
    const fetchTests = async () => {
        try {
            const response = await axios.get('/api/fetchTest.php');
            if (response.data && Array.isArray(response.data)) {
                setTests(response.data);
            } else {
                console.warn('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    // Kjør ved komponentmontasje
    useEffect(() => {
        fetchUsers();
        fetchTests();
    }, []);

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setModalOpen(true);
    };

    const handleUpdateUser = async () => {
        try {
            // Konstruer payloaden med `user_id` i stedet for `id`
            const payload = {
                user_id: selectedUser.id,
                email: selectedUser.email,
                first_name: selectedUser.first_name,
                last_name: selectedUser.last_name,
                role: selectedUser.role,
            };

            const response = await axios.post('/api/brukeradministrasjon/updateUser.php', payload);
            if (response.data.success) {
                // Oppdater brukeren i brukerlisten
                setUsers(users.map(user => user.id === selectedUser.id ? selectedUser : user));
                setAlertMessage('Brukeroppdatering vellykket!');
                setAlertColor('success');
                setModalOpen(false);
            } else {
                setAlertMessage('Kunne ikke oppdatere brukeren.');
                setAlertColor('danger');
                console.error('Failed to update user:', response.data.message);
            }
        } catch (error) {
            setAlertMessage('En feil oppsto ved oppdatering av brukeren.');
            setAlertColor('danger');
            console.error('Error updating user:', error);
        }
        setShowAlert(true);
    };

    const handleAssignLicenseClick = (user) => {
        setSelectedUser(user);
        setAssignLicenseModalOpen(true);
    };

    const handleAssignLicense = async () => {
        if (!selectedTestId) {
            setAlertMessage('Vennligst velg en test for å tildele lisenskode.');
            setAlertColor('warning');
            setShowAlert(true);
            return;
        }

        try {
            const response = await axios.post('/api/brukeradministrasjon/assignLicense.php', {
                user_id: selectedUser.id,
                test_id: selectedTestId
            });

            if (response.data.success) {
                setLicenseCode(response.data.code);
                setAlertMessage(`Lisenskode ${response.data.code} ble tildelt bruker ${selectedUser.first_name} ${selectedUser.last_name}.`);
                setAlertColor('success');
                // Hent oppdaterte brukere etter lisens tildeling
                fetchUsers();
                setAssignLicenseModalOpen(false);
            } else {
                setAlertMessage('Kunne ikke tildele lisenskoden.');
                setAlertColor('danger');
                console.error('Failed to assign license:', response.data.message);
            }
        } catch (error) {
            setAlertMessage('En feil oppsto ved tildeling av lisenskoden.');
            setAlertColor('danger');
            console.error('Error assigning license:', error);
        }
        setShowAlert(true);
    };

    const toggleEditModal = () => {
        setModalOpen(!modalOpen);
    };

    const toggleAssignLicenseModal = () => {
        setAssignLicenseModalOpen(!assignLicenseModalOpen);
    };

    return (
        <MDBContainer className="py-5">
            <h2 className="text-center mb-4">Brukeradministrasjon</h2>

                <MDBAlert color={alertColor} className="mt-3" dismiss open={showAlert} autohide>
                    {alertMessage}
                </MDBAlert>

            <MDBTable responsive>
                <MDBTableHead light>
                    <tr>
                        <th>#</th>
                        <th>E-post</th>
                        <th>Fornavn</th>
                        <th>Etternavn</th>
                        <th>Rolle</th>
                        <th>Handlinger</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.email}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.role}</td>
                            <td>
                                <MDBBtn color="primary" size="sm" onClick={() => handleEditClick(user)} className="me-2">
                                    <MDBIcon fas icon="edit" /> Rediger
                                </MDBBtn>
                                <MDBBtn color="info" size="sm" onClick={() => handleAssignLicenseClick(user)} className="me-2">
                                    <MDBIcon fas icon="key" /> Tildel Lisens
                                </MDBBtn>
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>

            {/* Modal for redigering */}
            {selectedUser && (
                <MDBModal open={modalOpen} onClose={toggleEditModal} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <h5 className="modal-title">Rediger Bruker</h5>
                                <MDBBtn className="btn-close" color="none" onClick={toggleEditModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput
                                    label="Fornavn"
                                    value={selectedUser.first_name}
                                    onChange={(e) => setSelectedUser({ ...selectedUser, first_name: e.target.value })}
                                    className="mb-3"
                                />
                                <MDBInput
                                    label="Etternavn"
                                    value={selectedUser.last_name}
                                    onChange={(e) => setSelectedUser({ ...selectedUser, last_name: e.target.value })}
                                    className="mb-3"
                                />
                                <MDBInput
                                    label="E-post"
                                    value={selectedUser.email}
                                    onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                                    className="mb-3"
                                />
                                <select
                                    className="form-select mb-3"
                                    value={selectedUser.role}
                                    onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}
                                >
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={toggleEditModal}>Avbryt</MDBBtn>
                                <MDBBtn color="primary" onClick={handleUpdateUser}>Lagre Endringer</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            )}

            {/* Modal for tildeling av lisens */}
            {selectedUser && (
                <MDBModal open={assignLicenseModalOpen} onClose={toggleAssignLicenseModal} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <h5 className="modal-title">Tildel Lisens til {selectedUser.first_name} {selectedUser.last_name}</h5>
                                <MDBBtn className="btn-close" color="none" onClick={toggleAssignLicenseModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <select
                                    className="form-select mb-3"
                                    value={selectedTestId}
                                    onChange={(e) => setSelectedTestId(e.target.value)}
                                >
                                    <option value="" disabled>Velg en test</option>
                                    {tests.map((test) => (
                                        <option key={test.id} value={test.id}>{test.name}</option>
                                    ))}
                                </select>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={toggleAssignLicenseModal}>Avbryt</MDBBtn>
                                <MDBBtn color="primary" onClick={handleAssignLicense}>Tildel Lisens</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            )}
        </MDBContainer>
    );
};

export default UserAdministration;
