import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Start som null for å kunne sjekke først

    useEffect(() => {
        // Når UserProvider først lastes, hent brukerdata fra serveren
        const fetchUserData = async () => {
            try {
                const response = await axios.get('/api/getUser.php');
                if (response.data.success) {
                    setUser(response.data.user);
                } else {
                    console.log('Ingen brukerdata tilgjengelig, brukeren er ikke logget inn.');
                }
            } catch (error) {
                console.error('Feil ved henting av brukerdata:', error);
            }
        };

        fetchUserData();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
