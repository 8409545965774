import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBBtn } from 'mdb-react-ui-kit';
import GraphSection from './graphSection/GraphSection';
import TableSection from './tableSection/TableSection';
import GuidesSection from './guidesSection/GuidesSection';
import TestsSection from './testsSection/TestsSection';
import AdminPanel from './adminPanel/AdminPanel';
import { UserContext } from '../userContext'; // Importer contexten
import axios from 'axios';

function Dashboard() {
    const [activeTab, setActiveTab] = useState('graphs');
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext); // Bruker contexten for å få tilgang til brukerinformasjon

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('/api/checkSession.php');
                if (response.data.success) {
                    // Sett brukerdata med hele 'user'-objektet fra responsen
                    setUser(response.data.user);
                } else {
                    console.error('Session data not valid. Redirecting to login.');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                navigate('/login');
            }
        };

        if (!user || !user.userId) {
            // Hvis brukerdata ikke er tilgjengelig i Context, prøv å hente det fra backend
            console.log("Henter brukerdata fra serveren...");
            fetchUserData();
        }
    }, [user, navigate, setUser]);


    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn'); // Fjern isLoggedIn fra localStorage
        setUser(null); // Fjern brukerdata fra context
        navigate('/login');
    };

    const handleTabClick = (tab) => setActiveTab(tab);

    return (
        <MDBContainer className="py-5" style={{ minHeight: '50vh' }}>
            {user && user.userId && (
                <>
                    <h2 className="text-center mb-4">Dashboard</h2>

                    {/* Logout Button */}
                    <MDBBtn color="danger" onClick={handleLogout} className="mb-4">
                        Logg ut
                    </MDBBtn>

                    {/* Tab Bar */}
                    <MDBTabs className="mb-4 justify-content-center">
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick('graphs')} active={activeTab === 'graphs'}>
                                Statistikk
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick('guides')} active={activeTab === 'guides'}>
                                Veiledninger
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick('tests')} active={activeTab === 'tests'}>
                                Tester
                            </MDBTabsLink>
                        </MDBTabsItem>
                        {user && user.role === 'admin' && (
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleTabClick('admin')} active={activeTab === 'admin'}>
                                    Admin
                                </MDBTabsLink>
                            </MDBTabsItem>
                        )}
                    </MDBTabs>

                    {/* Tab Content */}
                    <MDBTabsContent>
                        <MDBTabsPane open={activeTab === 'graphs'}>
                            <GraphSection />
                            <TableSection />
                        </MDBTabsPane>
                        <MDBTabsPane open={activeTab === 'guides'}>
                            <GuidesSection />
                        </MDBTabsPane>
                        <MDBTabsPane open={activeTab === 'tests'}>
                            <TestsSection />
                        </MDBTabsPane>
                        {user && user.role === 'admin' && (
                            <MDBTabsPane open={activeTab === 'admin'}>
                                <AdminPanel />
                            </MDBTabsPane>
                        )}
                    </MDBTabsContent>
                </>
            )}
        </MDBContainer>
    );
}

export default Dashboard;
