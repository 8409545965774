import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBContainer, MDBListGroup, MDBListGroupItem, MDBBtn, MDBTypography, MDBInput, MDBAlert } from 'mdb-react-ui-kit';
import Test from '../../components/test/Test';

const TestSection = () => {
    const [tests, setTests] = useState([]);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [licenseCode, setLicenseCode] = useState('');
    const [availableTests, setAvailableTests] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        // Fetch all available tests for the current user on component mount
        const fetchAvailableTests = async () => {
            try {
                const response = await axios.get('/api/fetchAvailableTests.php');
                if (response.data && Array.isArray(response.data.tests)) {
                    setAvailableTests(response.data.tests);
                } else {
                    console.warn('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching available tests:', error);
            }
        };

        fetchAvailableTests();
    }, []);

    // Funksjon for å validere lisenskode
    const handleValidateLicense = async () => {
        if (licenseCode.length !== 6) {
            setAlertMessage('Lisenskoden må være 6 siffer.');
            setAlertColor('warning');
            setShowAlert(true);
            return;
        }

        try {
            const response = await axios.post('/api/validateLicense.php', { code: licenseCode });
            if (response.data.success) {
                // Add the new available test and update the state
                setAvailableTests((prev) => [...prev, response.data.test]);
                setAlertMessage('Lisenskode godkjent! Testen er nå tilgjengelig.');
                setAlertColor('success');
                setLicenseCode(''); // Nullstill lisenskoden
            } else {
                setAlertMessage(response.data.message);
                setAlertColor('danger');
            }
            setShowAlert(true);
        } catch (error) {
            console.error('Error validating license:', error);
            setAlertMessage('En feil oppsto ved validering av lisenskoden.');
            setAlertColor('danger');
            setShowAlert(true);
        }
    };

    const handleStartTest = (testId) => {
        setSelectedTestId(testId);
    };

    return (
        <MDBContainer className="py-5">
            {!selectedTestId ? (
                <>
                    <MDBTypography tag='h3' className='my-3'>Tilgjengelige tester</MDBTypography>
                    <MDBAlert open={showAlert} className='mt-3 w-100' color={alertColor}>
                        {alertMessage}
                    </MDBAlert>
                    <MDBInput
                        label="Skriv inn lisenskode"
                        value={licenseCode}
                        onChange={(e) => setLicenseCode(e.target.value)}
                        maxLength={6}
                        className="my-3"
                    />
                    <MDBBtn onClick={handleValidateLicense}>Valider Lisenskode</MDBBtn>

                    <MDBListGroup className="mt-4">
                        {availableTests.length > 0 ? (
                            availableTests.map((test) => (
                                <MDBListGroupItem key={test.id} className="d-flex justify-content-between align-items-center">
                                    {test.name}
                                    <MDBBtn color="primary" onClick={() => handleStartTest(test.id)}>
                                        Start Test
                                    </MDBBtn>
                                </MDBListGroupItem>
                            ))
                        ) : (
                            <p>Ingen tilgjengelige tester ennå. Skriv inn lisenskode for å få tilgang.</p>
                        )}
                    </MDBListGroup>
                </>
            ) : (
                <Test testId={selectedTestId} onBack={() => setSelectedTestId(null)} />
            )}
        </MDBContainer>
    );
};

export default TestSection;
